import { createRouter, createWebHashHistory } from 'vue-router'

// import { useStore } from 'vuex' // 引入useStore 方法


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/cartFee',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '首页'
    },
    children:[
      {
        path:'/home/cartFee',
        name:'feeList',
        component: () => import('../views/Home/CartFee.vue')
      },
      {
        path:'/home/feiZuChe',
        name:'feiZuChe',
        component: () => import('../views/Home/FeiZuChe.vue')
      },
      {
        path:'/home/dayBill',
        name:'dayBill',
        component: () => import('../views/Home/DayBill.vue')
      },
      {
        path:'/home/userList',
        name:'users',
        component: () => import('../views/Home/UserList.vue')
      },
      {
        path:'/home/chart',
        name:'chart',
        component: () => import('../views/Home/Chart.vue')
      },
      {
        path:'/home/test',
        name:'test',
        component: () => import('../views/Home/Test.vue')
      }
    ]

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/home'
  }
]

function isTokenValid() {
  const token = localStorage.getItem('token')
  return token ? true : false
  // return true
}



const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path !== '/login' && !isTokenValid()) {
    next('/login')
  } else {
    next()
  }
})

export default router
