import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'


export default createStore({
  plugins:[
    createPersistedState()
    // createPersistedState({
    //   reducer(val){
    //     return {
    //       token:val.token
    //     }
    //   }
    // })
  ],
  state: {
    userInfo:{}
  },
  getters: {
  },
  mutations: {
    saveUserInfo(state,info){
      state.userInfo = info
    }
  },
  actions: {
  },
  modules: {
  }
})
